@import "_bootstrap-sass-grid";
@import "./libs/fonts";
@import "./libs/magnificPopup.css";
@import "./libs/owl.carousel.min.css";

$breakpoint-lg: 1200px;
$breakpoint-sm:990px;
$breakpoint-tablet:910px;
$breakpoint-mobile:760px;

$breakpoint-menu:983px;
$breakpoint-container:990px;


html,body{
    overflow-x: hidden;
}
body{
	background-color: white;
	color: #252525;
    line-height: 24px;
    font-family: 'Roboto Slab', serif;
    font-size: 15px;
    font-weight: 100;
    min-height: 100vh;
    background-image: url(../images/pageBg.jpg);
    background-repeat: repeat;
    .overflow{
        overflow-x: hidden;
    }
    i.icon {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'iconpack' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      &.icon-arrow-left:before {
        content: "\e900";
      }
      &.icon-arrow-right:before {
        content: "\e901";
      }
      &.icon-call:before {
        content: "\e902";
      }
      &.icon-close:before {
        content: "\e903";
      }
      &.icon-fb:before {
        content: "\e904";
      }
      &.icon-insta:before {
        content: "\e905";
      }
      &.icon-menu:before {
        content: "\e906";
      }
      &.icon-tel:before {
        content: "\e907";
      }
      &.icon-envelope:before {
        content: "\e908";
      }
    }
    a{
        cursor: pointer;
    }
    .noPadding{
        padding: 0px !important;
    }
    .call{
        display: none;
    }
    ul{
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
    }
    ul.defaultUl{
        margin: 0px;
        padding: 0px;
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
        li{
            list-style: none;
            margin: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            padding-left: 20px;
            &::before{
                content: "";
                position: absolute;
                margin-left: -20px;
                margin-top: 7px;
                width: 10px;
                height: 10px;
                border-radius: 10px;
                background-color: #b89c00;
            }
        }
    }
    .bg-znasirise{
        background-image: url("../images/znasiriseBg.png");
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 100% auto;
        .steps{
            font-size: 18px;
            font-weight: bold;
            a{
                margin-left: 10px;
                margin-right: 10px;
                cursor: pointer;
            }
        }
    }
    .formbtn{
        padding-bottom: 40px;
        button,a{
            border: none;
            color: #fff;
            background-color: #000;
            text-align: center;
            font-weight: 700;
            padding: 15px;
            display: block;
            text-decoration: none;
        }
        .pull-right{
            button{
                color: white;
                background-color: #d0b26b;
            }
        }
        @media screen and (max-width:400px) {
            .pull-left,.pull-right{
                width: 100%;
                float: none;
                clear: both;
                button,a{
                    width: 100%;
                    margin-bottom: 5px;
                }
            }
        }
    }

    .payType{
        @extend .col-sm-12;
        margin-top: 15px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        padding-top: 15px;
        border-top: 2px dashed #d0b26b;
        border-bottom: 2px dashed #d0b26b;
        font-weight: bold;
        .iradio_minimal-red{
            border-radius: 20px;
            overflow: hidden;
            margin-right: 15px;
        }
        img{
            height: 25px;
        }
        @media screen and (max-width:1199px) {
            .pull-right{
                float: none;
                clear: both;
                width: 100%;
                text-align: center;
                padding-top: 20px;
            }
        }
        .btn{
            border: none;
            color: #fff;
            background-color: #000;
            text-align: center;
            font-weight: 700;
            padding: 15px;
            display: block;
            text-decoration: none;
            width: 250px;
            margin-left: auto;
            margin-right: auto;
        }
        .gopays{
            width: 100%;
            text-align: center;
            padding-top: 20px;
        }
    }
    .note{
        @extend .col-sm-12;
        border-bottom: 2px dashed #d0b26b;
        padding-bottom: 15px;
        margin-bottom: 15px;
        a{
            color: black;
            font-weight: bold;
            &:hover{
                color: black;
                text-decoration: none;
            }
        }
    }
    .form{
        padding-top: 20px;
        border-bottom: 2px dashed #d0b26b;
        padding-bottom: 15px;
        margin-bottom: 15px;
        label{
            font-weight: bold;
            font-size: 13px;
            margin-bottom: 10px;
            margin-bottom: 10px;
            display: block;
        }
        input[type="text"],input[type="email"],textarea{
            padding: 10px !important;
            line-height: 20px !important;
            -webkit-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.1);
            border: 1px solid white;
            width: 100%;
        }
        textarea{
            height: 160px !important;
        }
        .form-group.error{
            input[type="text"],input[type="email"],textarea{
                border: 1px solid red;
            }
            p.help-block{
                color: red;
            }
        }
        p.help-block{
            margin: 0px;
            padding: 0px;
            line-height: 30px;
            height: 30px;
            display: block;
            width: 100%;
            font-size: 11px;
            ul{
                list-style: none;
                margin: 0px;
                padding: 0px;

            }
        }
        .icheckbox_minimal-red{
            margin-right: 10px;
        }

        .checkbox{
            @extend .col-sm-12;
            font-size: 12px;
            margin-bottom: 15px;
            a{
                color: black;
                text-decoration: none;
                &:hover{
                    border-bottom: 1px solid black;
                    padding-bottom: 3px;
                }
            }
        }
        span{
            margin-bottom: 20px;
            display: block;
        }
        .error.checkbox{
            color: red;
            .icheckbox_minimal-red{
                border: 1px solid red;
            }
        }
    }
    ol.defaultUl{
        margin: 0px;
        padding: 0px;
        -webkit-margin-before: 0px;
        -webkit-margin-after: 0px;
        -webkit-margin-start: 0px;
        -webkit-margin-end: 0px;
        -webkit-padding-start: 0px;
        list-style: none;
        counter-reset: li;
        padding-left: 20px;
        li{
            counter-increment: li;
            margin: 0px;
            margin-top: 10px;
            margin-bottom: 10px;
            &::before{
                position: absolute;
                margin-left: -20px;
                font-weight: bold;
                content: counter(li);
                color: #b89c00;
            }
        }
    }
    .fotogaleryLink{
        background-color: #d0b26b;
        color: white;
        display: block;
        width: 100%;
        font-weight: bold;
        text-align: center;
        height: 259px;
        vertical-align: center;
        display: table;
        text-decoration: none;
        span{
            display: table-cell;
            vertical-align: middle;
        }
    }
    .responsive-uls{
        ul{
            text-align: left;
            list-style: none;
            margin: 0px;
            li{
                line-height: 40px;
            }
            &:nth-child(even){
                li{
                    background-color: rgba(255,255,255,.6);
                }

            }
            &.title{
                li{
                    background-color: #d0b26b;
                    color: white;
                    font-weight: bold;
                    font-size: 20px;
                }
            }
        }
    }

    .text-center{
        text-align: center;
        img{
            margin-left: auto;
            margin-right: auto;
        }
    }
    .text-right{
        text-align: right;
    }
    .textColor{
        color: #b89c00;
    }
    .img-responsive{
        width: 100%;
        height: auto;
    }
    .blank{
        width: 100%;
        height: 50px;
    }
    .bold{
        font-weight: bold;
    }
    h1,h2,h3,h4{
        font-size: 41px;
        color: black;
        line-height: 52px;
    }
    h3{
        font-size: 20px;

        line-height: 40px;
    }
    h4{
        font-size: 25px;
        line-height: 30px;
    }
    .photos{
        display: block;
        margin-bottom: 20px;
        span{
            position: absolute;
            width: 100%;
            margin:15px;
            border: 2px solid #d2b56e;
            height: 100px;
            z-index: 999;
            display: block;
        }
    }
    .withHr::after{
        content: "";
        display: block;
        background-image: url("../images/hBg.svg");
        background-repeat: no-repeat;
        background-position: top center;
        margin-top: 15px;
        background-size: 319px;
        width: 319px;
        height: 39px;
        margin-left: auto;
        margin-right: auto;
    }
    .withWhiteHr::after{
        content: "";
        display: block;
        background-image: url("../images/hWhiteBg.svg");
        background-repeat: no-repeat;
        background-position: top center;
        margin-top: 15px;
        background-size: 319px;
        width: 319px;
        height: 39px;
        margin-left: auto;
        margin-right: auto;
    }
    .hrWhite{
        background-image: url("../images/hWhiteBg.svg");
        background-repeat: no-repeat;
        background-position: top center;
        width: 100%;
        height: 60px;
        background-size: auto 15px;
    }
    big{
        font-size: 36px;
        color: #696969;
    }
    #form .col-md-12.text-center big.bold.text-gradient{
        display: block;
        line-height: 38px;
        @media screen and (max-width:600px) {
            font-size: 26px;
            line-height: 28px;
        }
    }

    .text-gradient{
        background-image: -webkit-linear-gradient(left, #b89c00,  #d8b700); /* For Chrome and Safari */
        background-image:    -moz-linear-gradient(left, #b89c00,  #d8b700); /* For old Fx (3.6 to 15) */
        background-image:     -ms-linear-gradient(left, #b89c00,  #d8b700); /* For pre-releases of IE 10*/
        background-image:      -o-linear-gradient(left, #b89c00,  #d8b700); /* For old Opera (11.1 to 12.0) */
        background-image:         linear-gradient(to right, #b89c00,  #d8b700); /* Standard syntax; must be last */
        color:transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }
    .m-b-15{
        margin-bottom: 15px;
    }
    .m-b-40{
        margin-bottom: 40px;
    }
    .btns{
        border-radius: 4px;
        background-color: #d0b26b;
        color: white;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-left: 15px;
        padding-right: 15px;
        text-decoration: none;
        font-size: 13px;
        &.big{
            padding: 15px;
            padding-left: 25px;
            padding-right: 25px;
            font-size: 15px;
            font-weight: bold;
        }
    }
    .workshop-row{
        big{
            font-size: 30px;
            display:block;
            padding-bottom: 10px;
            line-height: 33px;
        }
        .stars{
            padding-top: 5px;
            padding-bottom: 15px;
            img{
                max-width: 15px;
            }
        }
        ul{
            list-style: none;
            -webkit-margin-before: 0px;
            -webkit-margin-after: 0px;
            -webkit-margin-start: 0px;
            -webkit-margin-end: 0px;
            -webkit-padding-start: 0px;
            padding-bottom: 15px;
            li{
                font-size: 14px;
                margin-bottom: 15px;
                a{
                    display: inherit;
                    color: black;
                    text-decoration: none;
                    border-bottom: 1px solid black;
                    padding-bottom: 3px;
                }
            }
        }
        a.btn{
            background-color: #d0b26b;
            color: white;
            font-weight: bold;
            padding: 15px;
            display: block;
            text-decoration: none;
            margin-right: 15px;
            width: 100%;
        }
        .price{
            background-color: black;
            color: white;
            text-align: center;
            padding: 5px;
            margin: 0px;
            strong,small{
                display: inline;
            }
            small{
                font-weight: 100;
                font-size: 12px;
            }
            span{
                font-size: 18px;
                display: block;
            }

        }
    }
    /* START modal options */
    .modal{
        background-color: rgba(0,0,0,.6);
        position: absolute;
        top: 0px;
        width: 100%;
        min-height: 100vh;
        z-index: 999;
        display: none;
        &.active{
            display: block;
        }
        .modal-body{
            margin-top: 60px;
            color: #64b302;
            background-color: #d0b26b;
            i.icon-ok{
                font-size: 50px;
                display: block;
                margin-top: 15px;
            }
            a.close-modal{
                position: absolute;
                right: 0px;
                margin-top: -40px;
                color: white;
                font-size: 30px;
                cursor: pointer;
            }
            .body{
                padding-top: 15px;
                padding-bottom: 15px;
            }
            h4{
                small{
                    display: block;
                    color: white;
                }
            }
            .header{
                .close-modal{
                    display: block;
                    width: 40px;
                    height: 40px;
                    background-color: black;
                    font-size: 22px;
                    line-height: 40px;
                    text-align: center;
                    right: 0px;
                    top: 40px;
                    &:hover{
                        color:#d0b26b;
                    }
                }
            }
        }
    }

    /* END modal optiaons */
    /* START news compoment */
    .news{
        margin-bottom: 40px;
        h3{
            margin-bottom: 5px;
            padding-bottom: 5px;
            line-height: 30px;
        }
        p{
            display: block;
            height: 120px;
            overflow: hidden;
            a{
                color: #d0b26b;
            }
        }
        a.btns{
            font-size: 17px;
            font-weight: bold;
        }
    }
    .news-detail{
        small{
            font-size: 18px;
            display: block;
            text-align: center;
            font-weight: bold;
            padding-top: 10px;
            padding-bottom: 10px;
        }
        .desc{
            font-style: italic;
            font-size: 18px;
        }
        hr{
            padding-top: 15px;
            margin-bottom: 20px;
            border:none;
            border-bottom: 2px dashed #d0b26b;
        }
    }
    /* END news compoment */

    /* START navline */
    #navLine{
        background-color: rgba(255,255,255,.4);
        line-height: 80px;
        p{
            margin: 0px;
            padding: 0px;
            font-weight: bold;
            font-size: 20px;
        }
        a{
            font-size: 15px;
            margin-left: 15px;
        }
    }
    /* END navline*/
    /* START head navigation */
    #head{
        .logo{
            padding-top: 30px;
            margin-top: 5px;
        }
        a.mobile-btn{
            display: none;
        }
        ul.nav{
            list-style: none;
            margin: 0px;
            padding: 0px;
            line-height: 100px;
            li{
                display: inline-block;
                padding-left: 40px;
                text-align: right;
                a{
                    font-size: 18px;
                    color: #252525;
                    text-decoration: none;
                    font-weight: bold;
                    padding-bottom: 4px;
                    &.active,&:hover{
                        border-bottom: 2px solid #d0b26b;
                    }
                }
            }
        }
    }
    /* END head navigation */
    /* START footer options */
    #footer{
        background-color: white;
        padding-top: 5px;
        line-height: 26px;
        p{
            color: #acacac;
            font-size: 14px;
            display: block;
            width: 100%;
            border-top:1px solid #efefef;
            padding-top: 10px;
            padding-bottom: 5px;
            a{
                color: #bf9b55;
                text-decoration: none;
            }
        }
        .gopay{
            .line{
                font-size: 13px;
                padding-top: 15px;
                border-top:1px solid #efefef;
                color: #acacac;
                a{
                    color: black;
                    font-weight: bold;
                    &:hover{
                        color: black;
                        text-decoration: none;
                    }
                }
                .pull-left{
                    padding-top: 0px;
                    strong{
                        background-image: linear-gradient(to right, #b89c00, #d8b700);
                        color: transparent;
                        -webkit-background-clip: text;
                        background-clip: text;
                        text-transform: uppercase;
                    }
                }
                .pull-right{
                    img{
                        height: 25px;
                        margin-left: 2px;
                        margin-right: 2px;
                    }
                }
            }
            @media screen and (max-width:1200px) {
                .pull-left,.pull-right{
                    text-align: center;
                    display: block;
                    width: 100%;
                    float: none;
                    clear: both;
                }
                .pull-left{
                    padding-bottom: 15px;
                }
            }

        }
        .copyright{
            margin-bottom: 0px !important;
            padding-bottom: 0px;
        }

        .contact{
            margin-bottom: 15px;
            line-height: 33px;
            h4{
                font-size: 20px;
                padding-bottom: 0px;
                margin-bottom: 10px;
                margin-top: 5px;
            }
            span{
                color: black;
                font-size: 14px;
            }
            a{
                color: black;
                text-decoration: none;
                padding-bottom: 4px;
            }
            .icon-tel{
                margin-left: 3px;
                margin-right: 2px;
            }
        }
        .social{
            line-height: 60px;
            a{
                color: #acacac;
                text-decoration: none;
                margin-left: 20px;
                display: inline-block;
                &:hover{
                    color: black;
                    text-decoration: none;
                }
                i{
                    margin-top: 15px;
                    margin-right: 10px;
                    font-size: 30px;
                    float: left;
                }
                span.lin{
                    line-height: 30px;
                    font-size: 14px;
                }
            }
        }
    }
    /* END footer options */
    .rowWithImg{
        background-color: #d0b26b;
        .col-md-4{
            background-color: #d0b26b;
        }
        .text{
            color: white;
            p{
                font-size: 15px;
            }
            h4{
                color: white;
                font-size: 25px;
            }
        }
        .photo{
            display: block;
            margin-bottom: 25px;
        }
        a.btn{
            background-color: white;
            padding: 15px;
            padding-left: 20px;
            padding-right: 20px;
            border-radius: 10px;
            text-decoration: none;
            font-weight: bold;
            font-size: 18px;
            text-transform: uppercase;
        }
    }
    h1.danger{
        color: red;
    }
    h1.success{
        color: #4CAF50;
    }
    .textdesc{
        a{
            color: black;
            text-decoration: none;
            font-weight: bold;
            &:hover{
                padding-bottom: 3px;
                border-bottom: 1px solid black;
            }
        }
    }
    .team{
        a{
            margin: 0px !important;
        }
        img{
            max-width: 120px;
            border: 2px solid white;
            border-radius: 150px;
        }
        strong{
            display: block;
            color: black;
            font-size: 18px;
            small{
                font-weight: 100;
                display: block;
                color: white;
                font-size: 16px;
            }
        }
    }
    .contact-block{
        strong{
            width: 120px;
            float: left;
        }
        a{
            color: black;
            text-decoration: none;
            &:hover{
                text-decoration: underline;
            }
        }
        iframe{
            border: 3px solid white;
        }
        @media screen and (max-width:766px) {
            &.col-md-7{
                padding-left: 0px;
            }
        }
    }
    .contact-form{
        background-color: #d0b26b;
        color: white;
        h1,h2,h3{
            color: white;
        }
        a{
            color: white;
        }
    }
    .form-group{
        input[type="text"],textarea{
            width: 100%;
            padding: 10px;
            background-color: white;
            border: 1px solid white;
            line-height: 40px;
            color: black;
        }
        textarea{
            height: 200px;
            line-height: 20px;
        }
        button{
            border: none;
            color: white;
            background-color: black;
            text-align: center;
            font-weight: bold;
            padding: 15px;
        }
        .checkbox{
            margin-top: 15px;
            padding-bottom: 5px;
            input{
                margin-right: 15px;
            }
            &.hasError{
                border-bottom: 1px dashed red;
            }
        }
        a{
            color: black;
            text-decoration: none;
            &:hover{
                border-bottom: 1px solid black;
                padding-bottom: 3px;
            }
        }
    }
}
#bottom-img{
    display: none;
}
/* START hp options */
.homePage{
    #head{
        .logo{
            position: absolute;
            padding-top: 0px !important;
            img{
                width: 190px;
            }

        }
    }
    .hp-left-cover{
        background-image: url(../images/hp-left-cover.jpg);
        background-repeat: no-repeat;
        background-position: top left;
    }
    .hp-right-cover{
        background-image: url(../images/hp-cover-right.jpg);
        background-repeat: no-repeat;
        background-position: top right;
    }
    #bottom-img{
        background-image: url(../images/hp-bootom.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        background-size: 100% auto;
        width: 100%;
        height: 330px;
        display: block;
    }
    .hp-img{
        background-image: url(../images/hp-tree.png);
        background-repeat: no-repeat;
        background-position: center top;

        padding-top: 180px;
        .hp-fly-man{
            position: absolute;
            right: 30px;
            margin-top: -140px;
        }
        .hp-shear{
            position: absolute;
            left:150px;
            margin-top: -120px;
        }
        .hp-image{
            max-width: 80%;
        }
        .hp-ballon{
            position: absolute;
            left:200px;
            bottom: -60px;
        }
        .hp-roe{
            position: absolute;
            left:-100px;
            bottom: -60px;
        }
        .hp-person{
            position: absolute;
            right: -100px;
            bottom: -30px;
        }
        .hp-glass{
            position: absolute;
            right: 240px;
            bottom: -30px;
        }
    }
    .hp-btns{
        padding-top: 35px;
        background-image: url(../images/hp-welcome-bg.jpg);
        background-repeat: no-repeat;
        background-position: center bottom;
        .welcome{
            padding-bottom: 30px;
        }
        a{
            background: #d1b46d; /* Old browsers */
            background: -moz-linear-gradient(left, #d1b46d 0%, #ac813b 50%, #d3b66f 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, #d1b46d 0%,#ac813b 50%,#d3b66f 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, #d1b46d 0%,#ac813b 50%,#d3b66f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#d1b46d', endColorstr='#d3b66f',GradientType=1 );
            border-radius: 4px;
            color: white;
            font-weight: 500;
            font-size: 18px;
            line-height: 40px;
            text-decoration: none;
            margin-top: 5px;
        }
        .btn{
            padding: 5px;
            padding-bottom: 0px !important;
        }
    }
    .hp-rabbit{
        position: absolute;
        left: -150px;
        margin-top: -80px;
    }
    .hp-rabbit2{
        position: absolute;
        right: -150px;
        margin-top: -80px;
    }
}


/* END hp options */



@media screen and (max-width:$breakpoint-menu) {
    #head{
        padding-top: 130px;
        padding-bottom: 60px;
        .call{
            display: block !important;
            position: absolute;
            color: #d0b26b;
            left: 15px;
            font-size: 40px;
            margin-top: -90px !important;
            z-index: 999;
            cursor: pointer;
            text-decoration: none;
        }
        .mobile-btn{
            display: block !important;
            position: absolute;
            color: #d0b26b;
            right: 15px;
            font-size: 40px;
            margin-top: -90px !important;
            z-index: 999;
            cursor: pointer;
            .icon-close{
                font-size: 30px;
            }
        }
        .logo{
            position: absolute;
            margin-top: -120px !important;
            margin-left: auto;
            margin-right: auto;
            width: 100%;
            text-align: center;
            left: 0px;
            padding-top: 5px !important;
            img{
                width: 120px !important;
            }
        }
        .nav{
            margin-top: 50px !important;
            display: none;
            background-color: #d0b26b;
            clear: both;
            width: 100%;
            &.active{
                display: block;
            }
            li{
                line-height: 60px !important;
                display: block !important;
                text-align: left !important;
                a{
                    display: block;
                    border-bottom: none !important;
                }
            }
        }
    }
}
@media screen and (max-width:$breakpoint-sm) {
    #navLine{
        .pull-left{
            float: none !important;
            display: block;
            width: 100% !important;
            text-align: center;
        }
        .pull-right{
            float: none;
            text-align: center;
            width: 100%;
            a{
                white-space: nowrap;
            }
        }
    }
    #footer{
        .contact{
            text-align: center;
        }
        .social{
            width: 100%;
            text-align: center;
        }
    }
    .form-group{
        p.pull-left{
            float: none !important;
            display: block;
            width: 100% !important;
            text-align: center !important;
            margin-top: 0px;
        }
        button{
            width: 100%;
        }
    }

}
@media screen and (min-width:$breakpoint-lg) {

}
@media screen and (max-width:933px) {
    .workshop-row{
        .price{
            text-align: center !important;
            .pull-left{
                float: none !important;
                clear: both;
                padding-bottom: 30px;
            }
            a.btn{
                float: none !important;
                clear: both;
            }
        }
    }
}
@media screen and (max-width:980px) {
    .homePage{
        .hp-roe{
            display: none;
        }
        .hp-ballon{
            left:20px !important;
            bottom: -60px;
        }
    }
}
@media screen and (max-width:883px) {
    .hp-btns{
        background: none !important;
    }
    #bottom-img{
        background-size: auto 300px !important;
        width: 100%;
        height: 330px;
    }
    .hp-right-cover{
        background: none !important
    }
}
@media screen and (max-width:667px) {
    #navLine{
        .col-md-12{
            padding: 0px !important;
        }
        .pull-right{
            clear: both;
            float: none !important;
            width: 100% !important;
        }
        a{
            display: block;
            float: left ;
            width: 48.5% !important;
            font-size: 15px;
            margin: 0px;
            line-height: 40px;
            margin-left: 1% !important;
            margin-bottom: 5px !important;
            padding-left: 5px !important;
            padding-right: 5px !important;
            font-size: 13px !important;
            line-height: 30px !important;
        }
    }
}
@media screen and (max-width:654px) {
    .homePage{
        .hp-fly-man{
            right: -140px !important;
        }
        .hp-shear{
            left:-80px !important;
        }
    }
}
@media screen and (max-width:528px) {
    .homePage{
        .hp-ballon{
            display: none;
        }
        .hp-person{
            display: none;
        }
        .hp-image{
            margin-top: 40px;
            max-width: 80%;
        }
        .hp-img{
            background-size: 120% auto !important;
        }
    }
}
@media screen and (max-width:414px) {
    .homePage{
        .hp-fly-man{
            right: -200px !important;
        }
    }
    #bottom-img{
        height: 240px !important;
    }
}
@media screen and (max-width:320px) {
    #navLine{
        a{
            display: block;
            float: none !important;
            width: 98% !important;
            line-height: 25px !important;
        }
    }
}
.row.display-flex {
    display: flex;
    flex-wrap: wrap;
    &::before{
        content: "";
        display: none;
    }
}
.row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
    float: none;
}

/* iCheck plugin Minimal skin, red
----------------------------------- */
.icheckbox_minimal-red,
.iradio_minimal-red {
    display: inline-block;
    *display: inline;
    vertical-align: middle;
    margin: 0;
    padding: 0;
    width: 18px;
    height: 18px;
    background: url(../images/red.png) no-repeat;
    border: none;
    cursor: pointer;
    background-color: white;
}

.icheckbox_minimal-red {
    background-position: 0 0;
}
    .icheckbox_minimal-red.hover {
        background-position: -20px 0;
    }
    .icheckbox_minimal-red.checked {
        background-position: -40px 0;
    }
    .icheckbox_minimal-red.disabled {
        background-position: -60px 0;
        cursor: default;
    }
    .icheckbox_minimal-red.checked.disabled {
        background-position: -80px 0;
    }

.iradio_minimal-red {
    background-position: -100px 0;
}
    .iradio_minimal-red.hover {
        background-position: -120px 0;
    }
    .iradio_minimal-red.checked {
        background-position: -140px 0;
    }
    .iradio_minimal-red.disabled {
        background-position: -160px 0;
        cursor: default;
    }
    .iradio_minimal-red.checked.disabled {
        background-position: -180px 0;
    }

/* HiDPI support */
@media (-o-min-device-pixel-ratio: 5/4), (-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi), (min-resolution: 1.25dppx) {
    .icheckbox_minimal-red,
    .iradio_minimal-red {
        background-image: url(../images/red@2x.png);
        -webkit-background-size: 200px 20px;
        background-size: 200px 20px;
    }
}
